@import "../theme.scss";

.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    flex-basis: 100%;
    &:not(:first-child) {
      margin-left: $form-margin-between;
    }
    &.b-50 {
      flex-basis: 50%;
    }
    &.b-100 {
      flex-basis: 50%;
    }
  }
}

app-input {
  width: 100%;
  display: block;
}

label {
  display: flex;
  text-align: left;
  color: $grey800;
  font-size: 1rem;
  @extend .font-regular;
  atom-tooltip {
    height: 15px;
    margin-left: 5px;
    margin-top: -2px;
  }
}

.requiered-symb {
  padding-left: 2px;
  color: $red100;
}

$mat-error-height: 1rem;
.field-wrapper {
  position: relative;
  .loaded-area {
    border: 1px dashed $green100;
    margin-bottom: 10px;
    max-width: 100%;
    box-sizing: border-box;
    overflow: scroll;
    .file-name {
      cursor: pointer;
      &:hover {
        color: $green100;
      }
    }
  }
  &.form-field-wrapper {
    margin-bottom: $mat-error-height;
    mat-error {
      font-size: 0.85rem;
      position: absolute;
      left: 0;
      bottom: -$mat-error-height;
      bottom: calc(0.25rem - 1.4rem);
      color: $red100;
    }
  }
  .field {
    background: #f3f4f5 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    width: calc(100% - 2px);
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid $primary400;
    outline: none;
    position: relative;
    height: $forms-atom-height;
    text-align: left;
    padding: 20px 16px 5px 16px;
    font:
      normal normal normal 14px/26px Nunito Sans,
      sans-serif;
    letter-spacing: 0.13px;
    color: #0f131c;

    &.compact {
      height: $forms-atom-compact-height;
      padding: 5px;
    }

    &.input-disabled {
      background-color: transparent !important;
      border-bottom: 1px solid $grey100;
    }

    &.multiple-select {
      .mat-select-trigger {
        margin-top: calc($forms-atom-height / 5.5);
      }
      &.mat-select-empty {
        .mat-select-trigger {
          margin-top: calc($forms-atom-height / 3.5);
        }
      }
    }

    &.action-icon-space {
      padding-right: 25px;
    }
    &:disabled,
    &:read-only:not(.mat-select),
    &.mat-select-disabled {
      background-color: #f3f4f5;
      box-shadow: none;
    }
    &:not(:disabled):not(.mat-select-disabled):not(
        :read-only:not(.mat-select)
      ) {
      &:active,
      &:hover,
      &:focus {
        outline: none;
        &.mat-select:focus {
          outline: none;
        }
      }
    }
    .mat-select-placeholder,
    &::placeholder {
      font-size: 11px;
      color: $grey200;
    }
    .mat-select-trigger {
      margin-top: calc($forms-atom-height/3.5);
    }
    .chips {
      border-radius: $border-radius;
      background-color: $grey100;
      margin-right: 10px;
      padding: 5px 8px;
      display: flex;
      align-items: center;
      .hidden-btn {
        padding: 0;
        margin-left: 5px;
        mat-icon {
          height: 19px;
          width: 19px;
          font-size: 19px;
        }
      }
    }
    &.preloading .mat-select-placeholder {
      display: none;
    }
    &.load-file {
      padding-top: 8px;
      height: auto;
      color: #b3b3b3;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .load-file-icon {
        display: flex;
        align-items: center;
      }
    }
    &.file-loaded {
      padding-top: 14px;
      display: flex;
      justify-content: space-between;
      .file-loaded-icon {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .action-icon {
    position: absolute;
    right: 8px;
  }
  .right {
    position: absolute;
    right: 8px;
    top: 33px;
    img {
      width: 20px;
    }
  }
  .preloader {
    position: absolute;
    bottom: 12px;
    left: 10px;
  }

  label {
    position: absolute;
    top: 1px;
    left: 16px;
    z-index: 10;
    font:
      normal normal normal 12px/16px Nunito Sans,
      sans-serif;
    text-align: left;
    letter-spacing: 0.4px;
    color: #0f131c;

    &.compact {
      display: none;
    }
  }

  &.fileover {
    .area .type-icon {
      animation: shake 1.5s;
      animation-iteration-count: infinite;
    }
  }
  .area {
    border: 1px dashed $blue100;
    transition: $fast-transition;
    cursor: pointer;
    min-height: 95px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &:hover:not(.disabled):not(.loaded) {
      border-style: solid;
      background-color: rgba($color: $blue100, $alpha: 0.2);
    }
    &.disabled {
      border: 1px dashed $grey200;
      cursor: auto;
      .load-area {
        opacity: 0.5;
        cursor: auto;
      }
    }
  }
  .area,
  .loaded-area {
    border-radius: $border-radius;
    padding: 10px;
    .type-icon {
      justify-content: center;
      margin-bottom: 5px;
      @include icon-color($grey600);
      &,
      svg {
        width: 40px;
        height: 40px;
        display: block;
        margin: auto;
      }
    }
    .load-icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    &.disabled,
    &.loaded {
      cursor: auto;
    }
    .load-area {
      margin-top: 7px;
      cursor: pointer;
    }
    .files-list {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 15px;
      flex-wrap: wrap;
    }
    .image {
      border-radius: $border-radius;
      border: $border;
      padding: 3px;
      height: 150px;
      width: 115px;
      box-sizing: border-box;
      position: relative;
      background-color: white;
      img {
        max-width: 100%;
        height: auto;
        width: auto;
        max-height: 100%;
        margin: auto;
        display: block;
        cursor: pointer;
      }
      .delete-btn {
        position: absolute;
        right: -1px;
        top: 0px;
        background-color: rgba($color: white, $alpha: 0.8);
        padding: 1px 0px;
      }
      .file-name {
        position: absolute;
        bottom: 3px;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
        background-color: rgba($color: white, $alpha: 0.8);
      }
    }
    .file {
      border-bottom: $border;
      border-color: $grey600;
      color: $grey600;
      padding-bottom: 1px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .file-name {
      max-width: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      &.view-only {
        max-width: none;
      }
    }
    .delete-icon {
      transition: $fast-transition;
      &:hover {
        transform: scale(1.2);
      }
      @include icon-color($red100);
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .actions-row {
      position: absolute;
      right: 5px;
      top: 5px;
      .delete-icon {
        @include icon-color($red100);
      }
    }
  }
}

form {
  .ng-invalid.ng-touched > .field-wrapper .field,
  .ng-invalid > .field-wrapper .field.ng-touched {
    border-bottom: 1px solid $red100;
  }
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-left: -10px;
}

mat-checkbox {
  display: flex;
  .mat-checkbox-frame {
    border-radius: $border-radius;
    border-color: $grey100;
  }
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $blue100;
  }
  &.xl {
    .mat-checkbox-inner-container {
      width: 20px;
      height: 20px;
    }
  }
  &.mat-checkbox-disabled {
    .mat-checkbox-frame {
      opacity: 0.4;
      border-color: $grey050;
    }
    .mat-checkbox-label {
      color: initial;
    }
  }
}

.mat-select-panel.selector-panel-list {
  overflow-y: scroll;
  max-height: 380px;
  height: 380px;
  width: 340px !important;
  .search-option,
  .new-element-option {
    height: 70px;
    position: static;
    padding: 0;
    background-color: white;
  }
  .empty {
    background-color: white;
    .mat-pseudo-checkbox {
      display: none;
    }
  }
  .item-option {
    height: auto;
    padding: 0 10px;
    &.mat-active {
      background: transparent;
    }
    &.mat-selected,
    &.mat-active.mat-selected {
      background-color: white;
      .box-row {
        background-color: $grey100;
      }
    }
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    .mat-pseudo-checkbox {
      position: absolute;
      right: 10px;
      top: 15px;
    }
    .box-row {
      min-height: 25px;
      margin: 6px 0;
      padding: 6px;
      background-color: $grey050;
      border-radius: $border-radius;
      .left {
        overflow: hidden;
        width: 100%;
      }
    }
    .info {
      max-width: 85%;
      margin-left: 10px;
      white-space: pre-line;
      .title {
        line-height: 14px;
        &.right-space {
          padding-right: 25px;
        }
      }
      .description {
        font-size: 0.92rem;
        line-height: 11px;
      }
    }
  }
  .mat-option.new-element-option {
    .mat-pseudo-checkbox {
      display: none;
    }
    .btn-wrapp {
      display: flex;
      position: absolute;
      padding: 10px;
      border-top: $border;
      bottom: 0;
      box-sizing: border-box;
      background-color: white;
      width: calc(100% + 20px);
    }
    .delete-btn {
      margin-left: auto;
      mat-icon {
        color: $red100;
      }
    }
  }

  .mat-option.new-element-option:hover {
    background: none;
  }

  .mat-select-search-inner {
    padding: 15px 10px;
    border-bottom: none;
    box-sizing: border-box;
  }
  .search-icon {
    z-index: 1000;
    position: absolute;
    left: 15px;
    top: 21px;
    height: 24px;
  }
  .mat-select-search-inside-mat-option button.mat-select-search-clear {
    top: 12px;
    right: 5px;
    mat-icon {
      font-size: 20px;
      color: $blue100;
    }
  }
  .mat-select-search-input {
    padding-left: 35px;
    @include field-shadow;
    &:focus,
    &:active,
    &:hover {
      @include field-shadow-active;
    }
  }
  .mat-option.mat-selected:not(.mat-option-multiple) {
    background-color: transparent;
  }
}

// Signature
.signature-wrapp {
  position: relative;
  .save-btn {
    padding: 0;
    margin-bottom: 2px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .clear-btn {
    color: $red100;
    padding: 0;
    @include icon-color($red100);
  }
  .actions {
    position: absolute;
    right: 5px;
    top: 28px;
    atom-button {
      width: 92px;
      button {
        height: 37px;
      }
    }
  }
  signature-pad .signature-pad-canvas,
  .signature-area {
    border-radius: $border-radius;
    border: 1px dashed $grey200 !important;
    &.signature-area {
      width: 100%;
      height: 200px;
      box-sizing: border-box;
      background-color: #faebd7;
    }
  }
}

/* Shake animation */
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.mat-calendar-body-selected {
  color: white !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
