@import "../theme.scss";
@import "../mixins.scss";

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-size: 11px;
  font-weight: 400;
  background: #f3f4f5;
}
* {
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  color: $grey800;
  @extend .font-medium;
}
h3 {
  @extend .font-l;
}
p {
  margin: 0;
}
b {
  font-weight: 800;
}

a {
  color: inherit;
  text-decoration: none;
  font-size: inherit;
}

button {
  cursor: pointer;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
  font-weight: 300;
  font-size: 1rem;
}

body .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.4);
}

svg-icon {
  display: flex;
}

.mat-drawer-backdrop {
  background-color: transparent !important;
}

.mat-menu-content {
  padding: 7px;
}

// Scroll bar
::-webkit-scrollbar-thumb,
::-webkit-bar-thumb {
  background: $blue100;
  cursor: pointer;
  border-radius: 0.5rem;
}
::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
  cursor: pointer;
}

.mat-tab-label {
  min-width: 145px !important;
}
.mat-mdc-option {
  min-height: 32px !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
}

.search-dropdown-container.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0;
  .mat-mdc-option {
    min-height: 36px !important;
    border-bottom: 2px solid #f3f4f5;
    &:hover {
      background: #f3f4f5;
    }
  }
}

.mdc-tab {
  height: 34px !important;
  background-color: #f3f4f5 !important;
  flex-grow: 0 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.mat-mdc-tab-links,
.mat-mdc-tab-labels {
  padding-top: 5px;
  padding-left: 5px;
}

.mdc-tab__text-label {
  color: $primary400 !important;
}

.mdc-tab--active {
  background-color: transparent !important;
  box-shadow: 2px 4px 12px -4px rgba(213, 214, 219, 1);
}

.mdc-tab-indicator__content {
  border: none !important;
}

.mat-drawer-container {
  color: $primary400 !important;
}

.mat-mdc-select-arrow {
  border-left: 12px solid transparent;
  border-top: 12px solid transparent;
  border-image-source: url("/assets/icons/chevron-down.png");
  width: auto;
  height: auto;
  svg {
    display: none;
  }
}

.custom-buttons {
  display: flex;
  align-items: center;
  margin-left: 30px;
  .custom-button {
    height: 32px;
    padding: 0 16px;
    border: none;
    background-color: #f3f4f5;
    color: $primary400;
    display: flex;
    align-items: center;
    .custom-button-icon {
      margin-right: 10px;
    }
    &.add {
      padding-left: 25px;
    }
    &.delete {
      background-color: red;
      color: white;
    }
    &.save {
      background-color: $primary400;
      color: white;
    }
    &:hover {
      filter: brightness(0.9);
    }
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:disabled {
      background: #e1e2e6;
      color: grey;
      cursor: auto;
      svg-icon {
        svg {
          fill: grey !important;
        }
      }
      &:hover {
        filter: none;
      }
    }
  }
}

.table-summary {
  width: 100%;
  background-color: #d5d6db;
  color: #28334a;
  display: flex;
  justify-content: stretch;
  margin-top: -2px;
  .summary-cell {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    position: relative;
    height: 34px;
    padding: 9px 12px;
    font-size: 12px;
    overflow: hidden;
  }
}

.table-toggle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 34px;
  padding: 9px 12px;
  font-size: 12px;
  overflow: hidden;
  color: #717171;
  border-bottom: 2px solid rgba(40, 51, 74, 0.05);
  cursor: pointer;
  .table-toggle-text {
    margin-right: 10px;
  }
}

.table-group-toggle {
  background: #f3f4f5;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 34px;
  padding: 9px 12px;
  font-size: 12px;
  overflow: hidden;
  color: $primary400;
  border-bottom: 2px solid rgba(40, 51, 74, 0.05);
  cursor: pointer;
  .table-group-toggle-text {
    margin-right: 10px;
  }
}

.table-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;

  & > span {
    margin-right: 10px;
  }
  .page-count {
    margin-right: 20px;
    .mdc-text-field {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 0 10px;
      .mat-mdc-form-field-infix {
        width: auto;
        padding-top: 9px !important;
        padding-bottom: 8px !important;
        min-height: auto !important;
        .mat-mdc-select-value {
          margin-right: 10px;
          color: $primary400;
        }
        .mat-mdc-select-arrow-wrapper {
          height: auto;
        }
      }
      .mdc-line-ripple::before {
        border: none;
      }
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .previous,
  .page-number,
  .next {
    background-color: #f3f4f5;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #00000029;
    }
  }

  .previous {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 12px;
    padding-left: 12px;
    margin-right: 3px;
    img {
      display: block;
      margin: auto;
    }
  }

  .page-number {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 12px;
    padding-left: 12px;
    margin-left: 3px;
    margin-right: 3px;
    &.active-page {
      background-color: $primary400;
      color: white;
    }
  }

  .next {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 12px;
    padding-left: 12px;
    margin-left: 3px;
    display: block;
    img {
      display: block;
      margin: auto;
    }
  }
}

.mat-mdc-checkbox .mdc-checkbox {
  transform: scale(0.75);
}

.mat-mdc-checkbox .mat-mdc-checkbox-touch-target {
  height: 43px;
}

.mat-mdc-checkbox-checked .mat-checkbox__background {
  transform: scale(0.75);
}

.mat-mdc-menu-item {
  min-height: 32px !important;
  padding-left: 4px !important;
}

.mat-mdc-dialog-surface {
  position: static !important;
}

.mat-mdc-tab-group {
  flex-grow: 1;
}

.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
}

.mat-mdc-slide-toggle {
  .mdc-switch {
    width: 28px !important;
    .mdc-switch__track {
      height: 12px !important;
    }
    .mdc-switch__handle-track {
      width: calc(100% - var(--mdc-switch-handle-width, 16px)) !important;
    }
    .mdc-switch__handle {
      width: 16px !important;
      height: 16px !important;
    }
  }

  &.mat-mdc-slide-toggle-checked {
    .mdc-switch {
      .mdc-switch__track::after {
        background: #6c82b7 !important;
      }
      .mdc-switch__handle::after {
        background: #28334a !important;
      }
    }
  }
  .mdc-switch__icons {
    display: none;
  }
}

.mat-mdc-select-trigger {
  height: 100%;
}

.row-tooltip .mdc-tooltip__surface {
  background: #e1e2e6 !important;
  color: $primary400;
}

.mdc-tooltip.row-tooltip::after {
  background-color: #e1e2e6;
}

.mdc-tooltip__surface {
  background: $primary400 !important;
  padding: 8px 16px 6px 16px !important;
}

.mdc-tooltip::after {
  content: "";
  position: absolute;
  top: -4px;
  left: calc(50% - 5px);
  width: 10px;
  height: 10px;
  background-color: $primary400;
  transform: rotate(45deg);
}

mark {
  background-color: #e7edf8;
}

.action-container {
  .action-header {
    font: normal normal normal 18px/24px Nunito Sans;
    letter-spacing: 0px;
    color: #28334a;
    padding: 10px 0;
  }

  .second-header {
    font: normal normal normal 16px/22px Nunito Sans;
    letter-spacing: 0px;
    color: #28334a;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .group-add-child {
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 14px/19px Nunito Sans;
    color: #28334a;
    height: 32px;
    gap: 10px;

    cursor: pointer;

    border: none;
    border-bottom: 2px solid #f3f4f5;
  }
}
