@import "../theme.scss";
@import "./buttons";

// MatMenuDropdown
.menu-dropdown.mat-menu-panel {
  margin-top: 16px;
  position: relative;
  overflow: visible;
  min-height: auto;
  &::before {
    position: absolute;
    top: -4px;
    left: 10px;
    content: "";
    height: 12px;
    width: 12px;
    background-color: white;
    transform: rotate(45deg);
  }
}

.mat-dialog-container {
  position: relative;
  .close {
    position: absolute;
    right: 5px;
    top: 10px;
    color: $grey800;
    @extend .hidden-btn;
  }
}

.cdk-overlay-pane .mat-dialog-container {
  $padding: 20px;
  padding: $padding;
  padding-top: 0;
  box-sizing: border-box;
  .dialog {
    > h3 {
      @extend .font-l;
      cursor: pointer;
      border-bottom: $border;
      padding-bottom: 1rem;
      @include replace-padding($padding);
      padding-right: 5rem;
      padding-top: $padding;
    }
    > .body {
      padding: $padding 20px;
      margin: 0 -20px;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 200px);
      .row {
        display: flex;
        justify-content: space-between;
        > * {
          flex-basis: 100%;
          &:not(:first-child) {
            margin-left: $form-margin-between;
          }
        }
      }
      mat-tab-group .mat-tab-header {
        margin: -20px -20px 10px;
      }
    }
    > .action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 1rem;
      @include replace-padding($padding);
      border-top: $border;
      > atom-button {
        flex-grow: 1;
        &:not(:first-of-type) {
          margin-left: $form-margin-between;
        }
      }
      &.right,
      & > .right {
        justify-content: flex-end;
      }
      &.left,
      & > .left,
      &.right,
      & > .right {
        display: flex;
        align-items: center;
        > atom-button {
          flex-grow: unset;
          width: 170px;
        }
        & > * {
          &:not(:first-child) {
            margin-left: $form-margin-between;
          }
        }
      }
    }
  }
}

.dialog-no-backdrop {
  width: fit-content;
  height: fit-content;
}

.hidden-dialog {
  opacity: 0;
}

.mat-menu-content {
  overflow: auto;
}

.menu-list .menu-item,
.menu-list .menu-item.mat-menu-item,
.menu-item {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: $grey050;
  border-radius: $border-radius;
  padding: 7px 10px;
  height: auto;
  line-height: inherit;
  min-width: 210px;
  font-size: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
  .mat-checkbox-inner-container {
    margin-right: 12px;
  }
  .mat-checkbox-label {
    font-weight: $medium;
    color: #000;
  }
  .drag-btn {
    margin-left: -5px;
    padding: 0 0 2px 0;
  }
  mat-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  svg-icon svg,
  & > svg {
    width: 26px;
    height: 26px;
  }
  @include icon-color($blue100);
}
.menu-item.nav-item {
  box-sizing: border-box;
  font-weight: 500;
  color: $blue100;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  &:active,
  &:hover {
    transition: $fast-transition;
    color: white;
    background-color: $blue100;
    @include icon-color(white);
  }
  .title {
    min-height: 18px;
    margin-left: 10px;
  }
}

.preload-container {
  padding: 200px 0;
  mat-spinner {
    margin: auto;
  }
}

mat-error {
  white-space: nowrap;
}
